import { inject } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as metaActions from '../actions/meta.action';

import { tap } from 'rxjs/operators';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { REQUEST } from 'src/express.tokens';
import { environment } from 'src/environments/environment';

export const setTitle$ = createEffect(
  (actions$ = inject(Actions), metaTitle = inject(Title)) => {
    return actions$.pipe(
      ofType(metaActions.SetTitle),
      tap(({ title }) => {
        metaTitle.setTitle(title);
      })
    );
  },
  { functional: true, dispatch: false }
);

export const setCanonical$ = createEffect(
  (actions$ = inject(Actions), meta = inject(Meta), request = inject(REQUEST, { optional: true })) => {
    return actions$.pipe(
      ofType(metaActions.SetCanonical),
      tap(({ url }) => {
        let domain = environment.canonicalDomain;
        domain = getCanonicalDomain(request?.hostname || '');

        meta.removeTag('rel=canonical');
        meta.addTag({ rel: 'canonical', href: `${domain}${url}` });
      })
    );
  },
  { dispatch: false, functional: true }
);

export const setMeta$ = createEffect(
  (actions$ = inject(Actions), meta = inject(Meta)) => {
    return actions$.pipe(
      ofType(metaActions.SetTags),
      tap(({ properties }) => {
        const keys = Object.keys(properties);
        const data: MetaDefinition[] = keys.map(a => {
          const property = a;
          const content = properties[a];

          if (property === 'description') {
            return { name: property, content } as MetaDefinition;
          }

          return { property, content };
        });
        meta.addTags(data);
      })
    );
  },
  { functional: true, dispatch: false }
);

const getCanonicalDomain = (url: string) => {
  if (!url) {
    return environment.canonicalDomain;
  }
  if (url.includes('localhost')) {
    return environment.canonicalDomain;
  }
  if (url.includes('msc.')) {
    return 'https://eurocrossmsc.nl';
  }
  if (url.includes('eurocross.nl')) {
    return 'https://eurocross.nl';
  }

  if (url.includes('eurocross.com')) {
    return 'https://eurocross.com';
  }

  if (url.includes('eurocrossmsc.nl')) {
    return 'https://eurocrossmsc.nl';
  }

  if (url.includes('en.eurocross.com.tr')) {
    return 'https://en.eurocross.com.tr';
  }
  if (url.includes('eurocross.com.tr')) {
    return 'https://eurocross.com.tr';
  }

  if (url.includes('en.eurocross.cz')) {
    return 'https://en.eurocross.cz';
  }
  if (url.includes('eurocross.cz')) {
    return 'https://eurocross.cz';
  }

  // staging domains
  if (url.includes('tr.fstr.io')) {
    return 'https://eurocross.com.tr';
  }
  if (url.includes('com.eurocross.fstr.io')) {
    return 'https://eurocross.com';
  }
  if (url.includes('sr.eurocross.fstr.io')) {
    return 'https://eurocross.com';
  }
  if (url.includes('tr-nl.eurocross.fstr.io')) {
    return 'https://eurocross.com.tr';
  }
  if (url.includes('tr-en.eurocross.fstr.io')) {
    return 'https://en.eurocross.com.tr';
  }
  if (url.includes('cz-cz.eurocross.fstr.io')) {
    return 'https://eurocross.cz';
  }
  if (url.includes('cz-en.eurocross.fstr.io')) {
    return 'https://en.eurocross.cz';
  }

  return environment.canonicalDomain;
};
